import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"
import styled from "styled-components"
import { FaPhoneAlt } from "react-icons/fa"

import Title from "../components/Title"
import { Layout, Seo } from "../components"

export const query = graphql`
  query GetPracticeArea($slug: String) {
    allDatoCmsPracticeArea(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          contact
          ext
          tel
          slug
          icon {
            alt
            gatsbyImageData(placeholder: BLURRED, backgroundColor: "grey")
            basename
          }
          image {
            alt
            gatsbyImageData(backgroundColor: "grey")
            title
          }
          id
          title
          info
          seoMetaTags {
            id
            tags
          }
          seo {
            description
            title
          }
        }
      }
    }

    datoCmsImage(image: { alt: { eq: "Am House St Alban Vermont" } }) {
      image {
        alt
        gatsbyImageData
      }
    }
  }
`

const PraticeAreasTemplate = ({ data }) => {
  const {
    allDatoCmsPracticeArea: { edges: practiceAreas },
    datoCmsImage: seoImg,
  } = data

  const {
    contact,
    ext,
    tel,
    title,
    info,
    image,
    description,
    title: seoTitle,
  } = practiceAreas[0].node
  const { alt, gatsbyImageData } = image
  // const { description, title: seoTitle } = practiceAreas[0].node.seo
  // const { title: seoTitle } = practiceAreas[0].node.seo

  const telephone = tel.toString().replace(/(\d{3})(\d{3})(\d{4})/, "$1.$2.$3")
  const telephonExt = ext

  const seoImage = seoImg.image.gatsbyImageData.images.fallback.src

  return (
    <Layout>
      <Seo title={seoTitle} description={description} image={seoImage} />
      <div className="page-header">
        <Container>
          <SectionHeading className="section">
            <Row>
              <Col>
                <article>
                  <Title title={title} />
                  <h3>{contact}</h3>
                  <p>
                    <FaPhoneAlt /> {telephone} ext: <span>{telephonExt}</span>
                  </p>
                </article>
              </Col>
            </Row>
          </SectionHeading>
        </Container>
      </div>

      <Container>
        <Section className="section">
          <Row>
            <Col md={6}>
              <article>
                <figure>
                  <GatsbyImage image={gatsbyImageData} alt={alt} />
                </figure>
              </article>
            </Col>

            <Col md={6}>
              <article>
                <div
                  className="blog-template__text-body"
                  dangerouslySetInnerHTML={{
                    __html: info,
                  }}
                ></div>
              </article>
            </Col>
          </Row>
        </Section>
      </Container>
    </Layout>
  )
}

const Section = styled.section`
  h3 {
    color: var(--clr-primary-1);
  }

  svg {
    color: var(--clr-primary-6);
  }
`

const SectionHeading = styled.section`
  @media screen and (max-width: 480px) {
    padding: 4rem 0 4rem 0;
  }

  h2.section-heading {
    text-align: center;
    color: var(--clr-primary-6);
    padding-top: 7rem;

    @media screen and (max-width: 480px) {
      padding-top: 2rem;
    }
  }

  article {
    text-align: center;

    p,
    h3 {
      color: var(--clr-primary-6);
    }

    h3 {
      margin-bottom: 1rem;
    }

    p {
      margin-bottom: 0;
    }
  }
`

export default PraticeAreasTemplate
